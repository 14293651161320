.grid-div {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1em;
  margin-bottom: 2em;
}

.grid-mobile-div {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1em;
  margin-bottom: 2em;
}
