.item1 {
  grid-area: titleArea;
}

.item2 {
  grid-area: contentArea;
}

.form-div {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1em;
  align-items: center;
}

.mobile-form-div {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 0.3em;
  align-items: center;
}
