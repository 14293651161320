@media (max-width: 500px) {
  label {
    font-family: Poppins;
    font-weight: bold;
    color: #4285f4;
    font-size: 0.7em;
  }

  text {
    font-family: Poppins;
    font-weight: bold;
    color: #27ae60;
    font-size: 0.7em;
  }
}

.App {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.App-Mobile {
  margin: 1em;
}

label {
  font-family: Poppins;
  font-weight: bold;
  color: #4285f4;
}

text {
  font-family: Poppins;
  font-weight: bold;
  color: #27ae60;
}

.form-control {
  font-family: Poppins;
  font-weight: bold;
}

.title {
  display: flex;
  font-size: 1.2em;
}
